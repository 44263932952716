import Vue from 'vue'

const QrBarCodeService = {
  // 条码列表
  all (param) {
    return Vue.http.get('code/index', param)
  },
  // 创建条码
  create (model) {
    return Vue.http.post('code/create', model)
  },
  // 预览条码
  preview (model) {
    return Vue.http.post('code/preview', model)
  },

  // 删除条码
  delete (param) {
    return Vue.http.delete('code/delete', param)
  },
  deleteAll (param) {
    return Vue.http.delete('code/delete-all', param)
  },
  export (param) {
    return Vue.http.download('POST', 'code/export', null, param)
  }
}

export default QrBarCodeService

<template>
  <div class="box">
    <router-link
      v-if="$can('admin/code/create')"
      :to="{ path: '/code/create' }"
    >
      <el-button
        type="primary"
        size="medium"
      >
        新建条码
      </el-button>
    </router-link>

    <div class="box-toolbar">
      <div class="box-item">
        <el-input
          placeholder="请输入条码"
          v-model="search_name"
          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-button
          icon="el-icon-search"
          circle
          @click="searchGo()"
        ></el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      border
      height="67vh"
      :stripe="true"
      :data="lists"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55"
      >
      </el-table-column>
      <!-- <el-table-column
        width="80"
        label="序号"
        prop="id"
      >
      </el-table-column> -->
      <el-table-column
        width="300"
        label="条形码"
        prop="uid"
      >
      </el-table-column>

      <el-table-column
        label="商品名称"
        prop="shop_id"
      >
        <template slot-scope="scope">
          <p>{{scope.row.temp.product_name}}</p>
        </template>
      </el-table-column>

      <el-table-column
        label="二维码图片"
        prop="code_cover"
      >
        <template slot-scope="scope">
          <img
            class="shop-img"
            @click="dialog(http_url + scope.row.file_path)"
            :src="http_url + scope.row.file_path + '?time=' + new Date()"
            alt=""
          >
        </template>
      </el-table-column>

      <el-table-column
        fixed="right"
        width="190"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="danger"
            plain
            size="mini"
            v-if="$can('admin/number/delete')"
            @click.native="handleModelDelete(scope.row)"
          >删除
          </el-button>

        </template>
      </el-table-column>
    </el-table>
    <div class="shop-i-bottom">
      <div>
        <el-button
          type="primary"
          plain
          v-if="$can('admin/number/export')"
          @click.native="Export"
        >
          下载所选
        </el-button>
        <el-button
          type="danger"
          plain
          v-if="$can('admin/number/delete-all')"
          @click.native="delAll"
        >
          删除所选
        </el-button>
      </div>
      <page
        class="shop-page"
        :pageSize="limit"
        :count="count"
        @change="pageChange"
        @changeSize="changeSize"
      >
      </page>
    </div>
    <!--  -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="700"
    >
      <div class="dialog-img">
        <img
          :src="dialogImgPath"
          alt=""
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QrBarCodeService from '@admin/services/QrBarCodeService'
import flatry from '@admin/utils/flatry'
import Page from '@admin/components/Pagination'
import pagination from '@admin/mixins/PaginationMixin'
import Auth from '@admin/utils/auth'

export default {
  name: 'QrBarCode',
  data () {
    return {
      http_url: '',
      ListArr: [],
      dialogVisible: false,
      dialogImgPath: '',
      search_name: '',
      function: function (param) {
        return QrBarCodeService.all(param)
      },
      delete: function (id) {
        return QrBarCodeService.delete(id)
      }
    }
  },
  async created () {
    this.http_url = Auth.getHostUrl()
  },
  components: {
    Page
  },
  mixins: [pagination],

  methods: {
    // 显示图片mack
    dialog (imgPath) {
      this.dialogVisible = true
      this.dialogImgPath = imgPath
    },
    // 搜索吧~
    async searchGo () {
      this.page = 1
      let param = {
        uid: this.search_name
      }
      this.where = param
      await this.getList(param)
    },
    async Export () {
      if (this.ListArr.length <= 0) {
        this.$message.warning('请选择需要下载的数据')
        return
      }
      const { data } = await flatry(
        QrBarCodeService.export(this.ListArr)
      )
      if (data) {
        this.$message.success(data.msg)
        window.open(data.data, '_blank')
        // this.$router.replace({ path: '/number/' })
      }
    },
    async delAll () {
      if (this.ListArr.length <= 0) {
        this.$message.warning('请选择需要删除的数据')
        return
      }
      const { data } = await flatry(
        QrBarCodeService.deleteAll(this.ListArr)
      )
      if (data) {
        for (let i = 0; i < this.ListArr.length; i++) {
          for (let j = 0; j < this.lists.length; j++) {
            if (this.ListArr[i].id === this.lists[j].id) {
              this.lists.splice(j, 1)
              break
            }
          }
        }
        this.$message.success(data.msg)
      }
    },
    handleSelectionChange (val) {
      this.ListArr = val
    }
  }
}
</script>
<style lang='scss' scoped>
.shop-img {
    width: 50px;
    height: 50px;
}
.shop-i-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    .shop-page {
        float: right;
        margin: 0;
    }
}
.dialog-img {
    img {
        width: 100% !important;
        height: 300px;
    }
}
</style>
